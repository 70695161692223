import { IMAGE_PUBLIC } from "shared/constants";
import {
  CHANNEL_IDENTITY_SEPARATOR,
  CHAT_MESSAGE_MEMBER_STATUS_READ,
  CHAT_MESSAGE_MEMBER_STATUS_UNREAD,
  CHAT_MESSAGE_TYPE_TEXT,
  LOCAL_STORAGE_MESSAGE_ITEMS_PEF,
  MESSAGE_MEDIA_STATUS_SEND,
  MESSAGE_STATUS_SEND,
} from "shared/constants/chat";
import { monthShort, weekDayFull } from "shared/constants/date";

import {
  deleteLocalStorageData,
  getLocalStorageData,
  getTimeFormatted,
  setLocalStorageData,
} from "./view";

/**
 * Get channel identity by users id
 * @param {Array} usersUid User id
 * @return {*}
 */
export const getChannelIdentity = (usersUid) => {
  const identity = usersUid.sort((a, b) => a - b);

  return identity.join(CHANNEL_IDENTITY_SEPARATOR);
};

/**
 * Get time in a specific format for chat channels
 * @param {number} time Time in miliseconds
 * @return {string}
 */
export function getChannelTime(time) {
  const date = new Date();
  let current = new Date();
  date.setTime(time);

  if (
    date.getMonth() === current.getMonth() &&
    date.getFullYear() === current.getFullYear()
  ) {
    if (date.getDate() === current.getDate()) {
      return getTimeFormatted(time, "pm", "am");
    }

    current.setDate(current.getDate() - 1);

    if (date.getDate() === current.getDate()) {
      return "Yesterday";
    }

    current = new Date();

    const day = current.getDay();
    const diff = current.getDate() - day + (day === 0 ? -6 : 1);

    current = new Date(current.setDate(diff));

    if (current.getTime() <= date.getTime()) {
      return weekDayFull[date.getDay()];
    }
  }

  return `${date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()}.${
    date.getMonth() >= 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
  }.${date.getFullYear()}`;
}

/**
 * Get message list time format
 * @param {number} time Create message time
 * @return {string}
 */
export function getMessageTime(time) {
  const date = new Date();
  const current = new Date();
  date.setTime(time);

  if (
    date.getMonth() === current.getMonth() &&
    date.getFullYear() === current.getFullYear()
  ) {
    if (date.getDate() === current.getDate()) {
      return "Today";
    }

    current.setDate(current.getDate() - 1);

    if (date.getDate() === current.getDate()) {
      return "Yesterday";
    }

    current.setDate(current.getDate() + 1);
  }

  return `${monthShort[date.getMonth()]} ${
    date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()
  }, ${date.getFullYear()}`;
}

/**
 * Get channel users index
 * @param {Array} members List of channel members
 * @param {number} userUid User id
 * @return {Object}
 */
export function getChatChannelUsersIndexes(members, userUid) {
  const users = {
    user: null,
    opponent: null,
  };

  if (!members || members.length < 2) {
    return users;
  }

  for (let iteration = 0; iteration < members.length; iteration++) {
    const member = members[iteration];

    if (member?.uid && member.uid !== userUid) {
      users.opponent = iteration;
    } else if (member?.uid && member.uid === userUid) {
      users.user = iteration;
    }
  }

  return users;
}

/**
 * Get opponent id from channel identity
 * @param {string} identity Channel identity
 * @param {number} userUid User id
 * @return {null|any}
 */
export function getChatOpponentUidFromIdentity(identity, userUid) {
  const uids = identity.split(CHANNEL_IDENTITY_SEPARATOR);

  if (uids?.length > 1) {
    for (const uid of uids) {
      if (+uid !== +userUid) {
        return uid;
      }
    }
  }

  return null;
}

/**
 * Get opponent id from channels
 * @param {Array|Object} channels List of channels
 * @param {number} userUid User id
 * @return {*[]}
 */
export function getChatOpponentsUidFromChannels(channels, userUid) {
  const usersId = [];

  Object.keys(channels).forEach((key) => {
    const uid = getChatOpponentUidFromIdentity(
      channels[key]?.identity,
      userUid
    );

    if (uid) {
      usersId.push(uid);
    }
  });

  return usersId;
}

/**
 * Get time in micro forma
 * @param {boolean} get_as_float
 * @return {number|string}
 */
export function microtime(get_as_float) {
  const now = new Date().getTime() / 1000;
  const s = parseInt(now);

  return get_as_float ? now : Math.round((now - s) * 1000) / 1000 + " " + s;
}

/**
 * Generate random int
 *
 * @param {number} max
 * @param {number} min
 * @return {*}
 */
export function random(max, min) {
  return Math.floor(Math.random() * (max - min)) + min;
}

/**
 * Get temporary message id
 * @param {number} authorUid Id of message author
 * @return {string}
 */
export function getTemporaryMessageKey(authorUid) {
  return `cl_m_message-${authorUid}-${microtime(true)}-${random(1, 500)}`;
}

const getTemporaryMessageFile = (file, created = null) => {
  created = created ?? new Date().getTime();

  return {
    sid: file.uid,
    private: IMAGE_PUBLIC,
    status: MESSAGE_MEDIA_STATUS_SEND,
    type: file.type,
    placeholder: "",
    src: "",
    preview: "",
    thumbnail: "",
    created,
  };
};

/**
 * Get temporary message object
 * @param {Object} channel Channel object
 * @param {Object} author User object of author
 * @param {Object} opponent User object of opponent
 * @param {string} body Message body
 * @param {number} index Message index
 * @param {number} type Type of message
 * @param {Array} files Files list
 * @param {Array | null} gift Gift data
 * @return {{author, created: number, channel, index: number, memberStatus: {[p: number]: {uid: *, readAt: null, status: number}|{uid: *, readAt: null, status: number}}, media: *[], gift: {} | null, body: string, type: number, updated: number, key: string, sid: string}}
 */
export function getTemporaryMessage(
  channel,
  author,
  opponent,
  body = "",
  index = 1,
  type = CHAT_MESSAGE_TYPE_TEXT,
  files = [],
  gift = null
) {
  const sid = getTemporaryMessageKey(author?.uid);
  const time = new Date().getTime();
  const media = {};

  if (files.length > 0) {
    for (const file of files) {
      media[file.uid] = getTemporaryMessageFile(file, time);
    }
  }

  return {
    sid,
    media: media,
    channel,
    author: author,
    body: body,
    index: index,
    key: sid,
    type: type,
    status: MESSAGE_STATUS_SEND,
    created: time,
    updated: time,
    gift: gift,
    memberStatus: {
      [author?.uid]: {
        uid: author?.uid,
        status: CHAT_MESSAGE_MEMBER_STATUS_READ,
        readAt: null,
      },
      [opponent?.uid]: {
        uid: opponent?.uid,
        status: CHAT_MESSAGE_MEMBER_STATUS_UNREAD,
        readAt: null,
      },
    },
    error: {
      show: false,
      message: null,
    },
  };
}

/**
 * Get time AM|PM
 * @param {number} time Time
 * @return {string}
 */
export function getTime(time) {
  return getTimeFormatted(time, "pm", "am");
}

/**
 *
 * @param {string} identity Channel identity
 * @param {string|number} sid Message identity
 */
export function deleteMessageItemFromStorage(identity, sid) {
  const key = LOCAL_STORAGE_MESSAGE_ITEMS_PEF + identity;
  const items = getLocalStorageData(key);

  if (items && Object.keys(items).length > 0 && items[sid]) {
    delete items[sid];

    if (Object.keys(items).length === 0) {
      deleteLocalStorageData(key);
    } else {
      setLocalStorageData(key, items);
    }
  }
}

/**
 * Drop all items of channel from storage
 * @param identity
 */
export function deleteMessageItemsFromStorage(identity) {
  deleteLocalStorageData(LOCAL_STORAGE_MESSAGE_ITEMS_PEF + identity);
}

/**
 * Add message to local storage
 * @param {string} identity Channel identity
 * @param {Object} item Message object
 */
export function addMessageItemToStorage(identity, item) {
  const key = LOCAL_STORAGE_MESSAGE_ITEMS_PEF + identity;
  const items = getLocalStorageData(key);

  if (items && Object.keys(items).length > 0) {
    items[item.sid] = item;
    setLocalStorageData(key, items);
  } else {
    setLocalStorageData(key, { [item.sid]: item });
  }
}

/**
 *
 * @param identity
 * @return {string}
 */
export function getMessageItemsFromStorage(identity) {
  const key = LOCAL_STORAGE_MESSAGE_ITEMS_PEF + identity;
  const items = getLocalStorageData(key);

  return items ?? {};
}

/**
 *
 * @param {array} arr
 * @return {object}
 */
export const getRandomValue = (arr) =>
  arr[Math.floor(Math.random() * arr.length)];

/**
 * if chat has mutual communication
 * @param {Object} channel current chat channel
 * @return {boolean} If has return true
 */
export const isHasMutualCommunication = (channel) => {
  if (channel.members?.length < 2) return false;

  return channel.members?.every(
    (member) => member.writeMessage && member.writeNotWink
  );
};

/**
 * Check if chat has active connection
 * @param {Object} channel current chat channel
 * @return {boolean} If connection active than return true
 */
export const isChatActiveConnection = (channel) => {
  return Boolean(channel.activeConnection);
};
